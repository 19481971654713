
import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getView, isGoodService } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import isOnline from 'is-online';

@Component({
  components: {
    TasqsDesktop: () => getView('TasqsDesktop'),
    TasqsMobile: () => getView('TasqsMobile'),
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   get myView() {
     return this.windowWidth < 768 ? 'tasqs-mobile' : 'tasqs-desktop';
   }

   mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
   }


   get isTimezonePosted(){
      return workflowModule.isTimezonePosted;
   }

   async created(){
    const isAppOnline = await isGoodService();
    if(isAppOnline && this.myView !== 'tasqs-mobile'  && !this.isTimezonePosted){
      await workflowModule.postUserNotificationSettings({});
      await workflowModule.setIsTimezonePosted(true)
    }

    //  tasqsListModule.setUsernamesList(accountModule.user.email.toLowerCase())
   }

   beforeDestroy() {
     window.removeEventListener('resize', this.onResize);
   }

   onResize() {
     this.windowWidth = window.innerWidth;
   }




}
